.scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #dddddd #e0e0e0;
  }
  
  .scrollbar::-webkit-scrollbar {
    height: 2px;
  }
  
  .scrollbar::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    border-radius: 2px;
  }
  
  .scrollbar::-webkit-scrollbar-track {
    background: #e0e0e0;
    border-radius: 2px;
  }
  