@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  font-family: "Nunito", "Segoe UI", arial;
}

.barlink {
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  transition: color 0.1s, background-color 0.1s;
}
/* .barlink:hover {
    color: #4dd0e1;
  }
  .barlink:focus, a:active {
    color: #00bcd4;
  } */

.barlink::before {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  height: 1.5px;
  width: 100%;
  background-color: #e25822;
  -webkit-transform-origin: center top;
  transform-origin: center top;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  transition: color 0.1s, -webkit-transform 0.2s ease-out;
  transition: color 0.1s, transform 0.2s ease-out;
  transition: color 0.1s, transform 0.2s ease-out,
    -webkit-transform 0.2s ease-out;
}

.barlink:active::before {
  background-color: #e25822;
}

.barlink:hover::before,
.barlink:focus::before {
  -webkit-transform-origin: center top;
  transform-origin: center top;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

::-webkit-scrollbar {
  width: 5px;
  overflow: auto;
  opacity: 0;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #777777;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* ::-webkit-scrollbar-button {
  background: #88888879;
  height: 12px;
  cursor: pointer;
} */

/* ::-webkit-scrollbar-button:active {
  background: #888888;
  height: 12px;
}

::-webkit-scrollbar-button:hover {
  background: #888888;
  height: 12px;
  cursor: pointer;
} */

::-webkit-scrollbar:hover {
  opacity: 1;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(-100%);
    opacity: 0;
    display: hidden;
    width: 0;
  }
}

.slide-in {
  animation: slideIn 0.5s ease-in-out forwards;
  display: block;
}

.slide-out {
  animation: slideOut ease-out forwards;
  animation-duration: 0.6s;
}

/* For small screens (sm: max-width: 424px) */
@media (max-width: 424px) {
  .slick-slide {
    display: inline-block;
    width: auto !important;
    margin-right: 4px;
  }

  .slick-list {
    overflow: hidden; /* Hide overflow */
  }

  .slick-track {
    display: flex;
    align-items: center;
  }
}

/* For medium screens (md: max-width: 767px) */
@media (max-width: 767px) {
  .slick-slide {
    display: inline-block;
    width: auto !important;
    margin-right: 8px;
  }

  .slick-list {
    overflow: hidden; /* Hide overflow */
  }

  .slick-track {
    display: flex;
    align-items: center;
  }
}

/* For large screens (lg: max-width: 1024px) */
@media (max-width: 1024px) {
  .slick-slide {
    display: inline-block;
    width: auto !important;
    margin-right: 8px;
  }

  .slick-list {
    overflow: hidden; /* Hide overflow */
  }

  .slick-track {
    display: flex;
    align-items: center;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
