@tailwind utilities;
@layer utilities{

  .hbm-body-heading-nunito-xl {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 49px;
    letter-spacing: -0.05em;
  }
  
  .hbm-body-heading-nunito-l {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 150%;
    letter-spacing: -0.05em;
  }
  
  .hbm-body-heading-nunito-m {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: -0.05em;
  }
  
  .hbm-body-heading-nunito-s {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: -0.03em;
  }
  
  .hbm-body-heading-nunito-xs {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.05em;
  }
  
  .hbm-body-nunito-xxl {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 150%;
  }
  
  .hbm-body-nunito-xl {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
  }
  
  .hbm-body-nunito-xl-light {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 24px;
    line-height: 150%;
  }
  
  .hbm-body-nunito-xl-bold {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
  }
  
  .hbm-body-nunito-l {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
  }
  
  .hbm-body-nunito-l-bold {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
  }
  
  .hbm-body-nunito-m {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
  }
  
  .hbm-body-nunito-m-bold {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
  }
  
  .hbm-body-nunito-m-light {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 150%;
  }
  
  .hbm-body-nunito-s {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
  }
  
  .hbm-body-nunito-s-light {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 150%;
  }
  
  .hbm-body-nunito-s-bold {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
  }
  
  .hbm-body-nunito-xs {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
  }
  
  .hbm-body-nunito-xs-light {
    font-family: 'Nunito', sans-serif;
    font-style: bold;
    font-weight: 200;
    font-size: 12px;
    line-height: 150%;
  }
  
  .hbm-body-nunito-xs-bold {
    font-family: 'Nunito', sans-serif;
    font-style: bold;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
  }
  
 
}